import { ContainerLayout } from '@exxonmobil/standard';
import honeyData from '../../data/HoneyData.json';

export interface HoneyDataItem {
  jobFamily: string;
  honeyComb: string;
  jobRole: string;
}

const Honey = () => {
  return (
    <ContainerLayout>
      <img alt="honeycomb" src={`./assets/honey_comb_job.png`} style={{ paddingTop: '1.5rem' }} />

      <div style={{ paddingTop: '2rem' }}>
        <h3>Job Role Mapping</h3>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Job Family</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Honeycomb</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Job Role</th>
          </tr>
        </thead>
        <tbody>
          {honeyData.map(({ jobFamily, jobRole, honeyComb }, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{jobFamily}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{honeyComb}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{jobRole}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerLayout>
  );
};

export default Honey;
