import { ContainerLayout, GridItem, GridLayout, Tag, TagGroup } from '@exxonmobil/standard';
import { useContext, useEffect } from 'react';
import { SearchContext } from '../../contexts/searchContext';
import orgData from '../../data/OrgData.json';
import teamData from '../../data/TeamData.json';
import '../../styles/home.css';
import OrganizationCard from '../OrganizationCard';
import TeamCard from '../TeamCard';
import Toolbar from '../ToolBar';

const Home = () => {
  const data = useContext(SearchContext);

  useEffect(() => {
    // getEMITData()
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     return response.json();
    //   })
    //   .then((emitData: OrganizationDataValidation[]) => {
    //     console.log(emitData);
    //     setData(emitData);
    //   })
    //   .catch((error) => {
    //     console.error('There has been a problem with your fetch operation:', error);
    //   });
    data?.setInitialData(teamData);
    data?.setSearchResults(teamData);
    data?.setOrgData(orgData);
    data?.setOrganizations(orgData.map((org) => org.organizationAbbreviation));
    data?.setIsOrganizationsView(
      !(data?.searchState.query && data?.searchState.organizations && data?.searchState.jobRoles)
    );
  }, []);
  return (
    <ContainerLayout type="em-l-container">
      <Toolbar />
      <div className="center">
        {!data?.searchState.isOrganizationsView && (
          <h6 style={{ marginRight: '0.5rem', fontSize: '0.95rem' }}>Filter by:</h6>
        )}
        <TagGroup type="em-c-tags--light">
          {!data?.searchState.isOrganizationsView && data?.searchState.organizations.map((org) => <Tag label={org} />)}
          {!data?.searchState.isOrganizationsView && data?.searchState.query && <Tag label={data?.searchState.query} />}
        </TagGroup>
      </div>
      <GridLayout type="em-l-grid--2up">
        {data?.searchState.isOrganizationsView &&
          data?.searchState.orgData.map((org, index) => {
            return (
              <GridItem key={org.organizationName} optionalClass="org-grid">
                <OrganizationCard data={org} index={index} />
              </GridItem>
            );
          })}
      </GridLayout>
      <GridLayout type="em-l-grid--1-2-4up" breakType="em-l-grid--break-slow">
        {!data?.searchState.isOrganizationsView &&
          data?.searchState.results
            .filter((team) => data.searchState.organizations.includes(team.organization.abbreviation))
            // .filter((org) => data.searchState.jobRoles.includes(org.team.))
            .map((team, index) => (
              <GridItem key={team.abbreviation}>
                <TeamCard
                  key={team.abbreviation}
                  subTeamData={team}
                  team={team.parentTeam.name}
                  org={team.organization.abbreviation || ''}
                  index={index}
                />
              </GridItem>
            ))}
      </GridLayout>
    </ContainerLayout>
  );
};

export default Home;
