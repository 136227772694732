import { HorizontalHeader, Logo, Navigation, NavigationItem } from '@exxonmobil/standard';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/header.css';
import { useSearchContext } from '../../hooks/useSearchContext';
import orgData from '../../data/OrgData.json';

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setIsOrganizationsView, setOrganizations } = useSearchContext();

  const paths = {
    compare: '/compare',
    honey: '/honey',
    metrics: '/metrics',
    careerExample: '/career-example'
  };

  const navigationItems = [
    { path: paths.honey, label: 'EMIT Honey Comb' },
    { path: paths.careerExample, label: 'Career Path Example' },
    { path: paths.metrics, label: 'Metrics' },
    { path: paths.compare, label: 'Compare' }
  ];

  const handleLogoClick = () => {
    navigate('/');
    setIsOrganizationsView(true);
    setOrganizations(orgData.map((org) => org.organizationAbbreviation));
  };

  return (
    <HorizontalHeader optionalClass="em-u-margin-bottom-none" title="">
      <div
        onClick={handleLogoClick}
        style={{
          cursor: 'pointer',
          display: 'flex',
          paddingTop: '0.8rem',
          marginLeft: '-2rem'
        }}
      >
        <Logo style={{ color: 'red' }} />
        <p
          style={{
            color: 'grey',
            fontSize: '1.05em'
          }}
        >
          | EMIT TH Career Library
        </p>
      </div>
      <Navigation>
        {navigationItems.map(({ path, label }) => (
          <NavigationItem key={path} current={pathname === path} label={label} onClick={() => navigate(path)} />
        ))}
      </Navigation>
    </HorizontalHeader>
  );
};

export default Header;
