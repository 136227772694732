import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '0cd7da4f-ac1d-48c7-971b-a2538312e8f6',
    authority: 'https://login.microsoftonline.com/d1ee1acd-bc7a-4bc4-a787-938c49a83906/',
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};
