import { Navigate, Route, Routes } from 'react-router-dom';
import Compare from './components/Compare/Compare';
import Footer from './components/Footer';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import { SearchProvider } from './contexts/searchContext';
import './styles/global.css';
import { CompareProvider } from './contexts/compareContext';
import Honey from './components/Honey/Honey';
import Metrics from './components/Metrics/Metrics';
import CareerExample from './components/CareerExample/CareerExample';

function App() {
  return (
    <SearchProvider>
      <CompareProvider>
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/honey" element={<Honey />} />
            <Route path="/metrics" element={<Metrics />} />
            <Route path="/career-example" element={<CareerExample />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </CompareProvider>
    </SearchProvider>
  );
}

export default App;
