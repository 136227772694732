import {
  Button,
  ButtonBar,
  Checkbox,
  CheckboxDropdown,
  Toolbar as FooterStandard,
  Search,
  ToolbarItem
} from '@exxonmobil/standard';
import { useEffect, useState } from 'react';
import { useSearchContext } from '../hooks/useSearchContext';
import '../styles/toolbar.css';
import { getSearchResult } from '../services/api/searchService';
import orgData from '../data/OrgData.json';

const TIMEOUT_DELAY = 500;
// const MIN_TEXT_LENGTH = 3;

const Toolbar = () => {
  const organizations = orgData.map((org) => org.organizationAbbreviation);
  // const jobRoles = ['Software Engineer', 'Data Engineer', 'Business Analyst'];

  const { searchState, setSearchQuery, setOrganizations, setSearchResults, setIsOrganizationsView } =
    useSearchContext();
  const [searchInput, setSearchInput] = useState(searchState.query);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(searchState.organizations);
  // const [selectedJobRoles, setSelectedJobRoles] = useState<string[]>([]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // if (searchInput.length >= MIN_TEXT_LENGTH) {
      setSearchQuery(searchInput);
      const searchResults = getSearchResult(searchInput);
      setSearchResults(searchResults);
      // }
    }, TIMEOUT_DELAY);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchInput]);

  const handleOrganizationChange = (organization: string) => {
    if (!(selectedOrganizations.length === 1 && selectedOrganizations.includes(organization))) {
      const updatedSelectedOrganizations = selectedOrganizations.includes(organization)
        ? selectedOrganizations.filter((org) => org !== organization)
        : [...selectedOrganizations, organization];
      setSelectedOrganizations(updatedSelectedOrganizations);
      setOrganizations(updatedSelectedOrganizations);
    }
  };

  // const handleJobRoleChange = (jobRole: string) => {
  //   const updatedSelectedJobRoles = selectedJobRoles.includes(jobRole)
  //     ? selectedJobRoles.filter((role) => role !== jobRole)
  //     : [...selectedJobRoles, jobRole];

  //   setSelectedJobRoles(updatedSelectedJobRoles);
  //   setJobRoles(updatedSelectedJobRoles);
  // };

  const handleInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(value);
    setSearchQuery(value);
    setIsOrganizationsView(false);
  };

  return (
    <div className="center" style={{ paddingTop: '1rem' }}>
      <FooterStandard collapsible>
        <ToolbarItem>
          <Search
            onChange={handleInputChange}
            onSubmit={(e: { preventDefault: () => void }) => {
              e.preventDefault();
            }}
            placeholder="Search"
            size="em-c-search-form--small"
            value={searchInput}
          />
        </ToolbarItem>
        {!searchState.isOrganizationsView && (
          <ToolbarItem>
            <CheckboxDropdown dropdownLabel="Organization" size="em-c-btn--small">
              {organizations.map((organization) => (
                <Checkbox
                  key={organization}
                  checked={searchState.organizations.includes(organization)}
                  inline
                  label={organization}
                  onChange={() => handleOrganizationChange(organization)}
                  value={organization}
                />
              ))}
            </CheckboxDropdown>
          </ToolbarItem>
        )}
        {/* <ToolbarItem>
          <CheckboxDropdown dropdownLabel="Job Role" size="em-c-btn--small">
            {jobRoles.map((jobRole) => {
              return (
                <Checkbox
                  key={jobRole}
                  checked={selectedJobRoles.includes(jobRole)}
                  inline
                  label={jobRole}
                  onChange={() => handleJobRoleChange(jobRole)}
                  value={jobRole}
                />
              );
            })}
          </CheckboxDropdown>
        </ToolbarItem> */}
        <ToolbarItem>
          <ButtonBar>
            <Button
              className={`em-c-btn em-c-btn--small em-js-btn-selectable ${
                searchState.isOrganizationsView ? 'em-is-active' : ''
              }`}
              iconName="visibility-on"
              isIconButton
              label="Organization"
              onClick={() => {
                setIsOrganizationsView(true);
              }}
              selectable
              size="em-c-btn--small"
            />
            <Button
              className={`em-c-btn  em-c-btn--small em-js-btn-selectable ${
                !searchState.isOrganizationsView ? 'em-is-active' : ''
              }`}
              iconName="visibility-on"
              isIconButton
              label="Team"
              onClick={() => {
                setIsOrganizationsView(false);
              }}
              selectable
              size="em-c-btn--small"
            />
          </ButtonBar>
        </ToolbarItem>
      </FooterStandard>
    </div>
  );
};

export default Toolbar;
