import { ContactCard, GridItem, GridLayout, LinkListItem, Section, Tag, TagGroup, Tooltip } from '@exxonmobil/standard';
import '../styles/teamcontent.css';
import { TeamContentTypes } from '../types/TeamContentTypes';
import InternalTeamCard from './InternalTeamCard';
import { TeamDataValidation } from '../types/TeamDataTypes';

const ScopeOfWork = ({ data }: { data: TeamDataValidation }) => (
  <ul className="em-c-bulleted-list ">
    {data.scopeOfWork.map((scopeOfWork) => (
      <li className="em-c-bulleted-list__item " key={scopeOfWork}>
        {scopeOfWork}
      </li>
    ))}
  </ul>
);
const Skills = ({ data }: { data: TeamDataValidation }) => (
  <>
    {!!data.requiredSkills?.length && (
      <>
        <h6>Required Skills</h6>
        <ul className="em-c-bulleted-list ">
          {data.requiredSkills.map((requiredSkill) => (
            <li className="em-c-bulleted-list__item " key={requiredSkill}>
              {requiredSkill}
            </li>
          ))}
        </ul>
      </>
    )}
    {!!data.acquiredSkills?.length && (
      <>
        <h6>Acquired Skills</h6>
        <ul className="em-c-bulleted-list ">
          {data.acquiredSkills.map((acquiredSkill) => (
            <li className="em-c-bulleted-list__item " key={acquiredSkill}>
              {acquiredSkill}
            </li>
          ))}
        </ul>
      </>
    )}
    {!!data.technologyKnowledge?.length && (
      <>
        <h6 style={{ paddingBottom: '0.7rem' }}>Technology Knowledge</h6>
        <TagGroup>
          {data.technologyKnowledge.map((tech) => (
            <Tag key={tech} label={tech} optionalClass="tech-tag" />
          ))}
        </TagGroup>
      </>
    )}
  </>
);
const Customer = ({ data }: { data: TeamDataValidation }) => (
  <ul className="em-c-bulleted-list ">
    {data.customers.map((customer) => (
      <li className="em-c-bulleted-list__item " key={customer}>
        {customer}
      </li>
    ))}
  </ul>
);
const Notes = ({ data }: { data: TeamDataValidation }) => <p>{data.notes}</p>;
const JobRoles = ({ data }: { data: TeamDataValidation }) => (
  <>
    {data.jobRoles.map((job) => (
      <Tooltip key={job.abbreviation} content={job.name}>
        <div className="job-icon">{job.abbreviation ? job.abbreviation : job.name[0]}</div>
      </Tooltip>
    ))}
  </>
);
const Team = ({ data }: { data: TeamDataValidation }) => (
  <>
    <GridLayout type="em-l-grid--2up">
      {data.internalSubTeam.map((team) => {
        return (
          <GridItem key={team.name}>
            <InternalTeamCard data={team} />
          </GridItem>
        );
      })}
    </GridLayout>
  </>
);
// const Contact = ({ data }: { data: TeamDataValidation }) => (
//   <>
//     <GridLayout type="em-l-grid--2up">
//       {data.contactPoints.map((contact) => (
//         <>
//           <ContactCard
//             key={contact.email}
//             email={contact.email}
//             location="Bangkok, Thailand"
//             name={contact.name}
//             team={contact.role}
//             optionalClass="margin-left"
//           >
//             <LinkListItem iconName="building" link="https://goto/EMIT">
//               EMIT
//             </LinkListItem>
//             {/* <LinkListItem iconName="phone" link="tel:+01 555-555-5555">
//               +01 555-555-5555
//             </LinkListItem> */}
//           </ContactCard>
//         </>
//       ))}
//     </GridLayout>
//   </>
// );
const TeamContent = ({ subTeamData, subTeamCompareData }: TeamContentTypes) => {
  if (subTeamCompareData) {
    return (
      <>
        {(!!subTeamData.scopeOfWork?.length || !!subTeamCompareData.scopeOfWork?.length) && (
          <Section title="Scope of work" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem>
                <ScopeOfWork data={subTeamData} />
              </GridItem>
              <GridItem optionalClass="padding-left">
                <ScopeOfWork data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )}
        {(!!subTeamData.requiredSkills?.length ||
          !!subTeamData.acquiredSkills?.length ||
          !!subTeamData.technologyKnowledge?.length) && (
          <Section title="Skills" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem>
                <Skills data={subTeamData} />
              </GridItem>
              <GridItem optionalClass="padding-left">
                <Skills data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )}
        {!!subTeamData.customers?.length && (
          <Section title="Customers" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem>
                <Customer data={subTeamData} />
              </GridItem>
              <GridItem optionalClass="padding-left">
                <Customer data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )}
        {!!subTeamData.notes && (
          <Section title="Notes" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem>
                <Notes data={subTeamData} />
              </GridItem>
              <GridItem>
                <Notes data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )}
        {!!subTeamData.jobRoles?.length && (
          <Section title="Job Roles" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem optionalClass="job-grid">
                <JobRoles data={subTeamData} />
              </GridItem>
              <GridItem optionalClass="job-grid padding-left">
                <JobRoles data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )}
        {!!subTeamData.internalSubTeam?.length && (
          <Section title="Team" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem>
                <Team data={subTeamData} />
              </GridItem>
              <GridItem optionalClass="padding-left">
                <Team data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )}
        {/* {!!subTeamData.contactPoints?.length && (
          <Section title="Contact Point" optionalClass="em-c-section">
            <GridLayout type="em-l-grid--2up">
              <GridItem>
                <Contact data={subTeamData} />
              </GridItem>
              <GridItem optionalClass="padding-left">
                <Contact data={subTeamCompareData} />
              </GridItem>
            </GridLayout>
          </Section>
        )} */}
      </>
    );
  } else {
    return (
      <>
        {!!subTeamData.scopeOfWork?.length && (
          <Section title="Scope of work" optionalClass="em-c-section--split">
            <ScopeOfWork data={subTeamData} />
          </Section>
        )}
        {(!!subTeamData.requiredSkills?.length ||
          !!subTeamData.acquiredSkills?.length ||
          !!subTeamData.technologyKnowledge?.length) && (
          <Section title="Skills" optionalClass="em-c-section--split">
            <Skills data={subTeamData} />
          </Section>
        )}
        {!!subTeamData.customers?.length && (
          <Section title="Customers" optionalClass="em-c-section--split">
            <Customer data={subTeamData} />
          </Section>
        )}
        {!!subTeamData.notes && (
          <Section title="Notes" optionalClass="em-c-section--split">
            <Notes data={subTeamData} />
          </Section>
        )}
        {!!subTeamData.jobRoles?.length && (
          <Section title="Job Roles" optionalClass="em-c-section--split">
            <JobRoles data={subTeamData} />
          </Section>
        )}
        {!!subTeamData.internalSubTeam?.length && (
          <Section title="Team" optionalClass="em-c-section--split">
            <Team data={subTeamData} />
          </Section>
        )}
        {/* {!!subTeamData.contactPoints?.length && (
          <Section title="Contact Point" optionalClass="em-c-section--split">
            <Contact data={subTeamData} />
          </Section>
        )} */}
      </>
    );
  }
};

export default TeamContent;
