import { FooterItem, Footer as FooterStandard } from '@exxonmobil/standard';

const Footer = () => {
  return (
    <FooterStandard dark optionalClass="em-c-home-page__footer">
      <FooterItem value="Disclaimer: EMIT TH data only" />
      {/* <FooterItem onClick={() => {}} url="#" value="Navigation link 2" />
      <FooterItem onClick={() => {}} url="#" value="Navigation link 3" />
      <FooterItem onClick={() => {}} url="#" value="Navigation link 4" /> */}
    </FooterStandard>
  );
};

export default Footer;
