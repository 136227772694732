import Fuse from 'fuse.js';
import teamData from '../../data/TeamData.json';
import { TeamDataValidation } from '../../types/TeamDataTypes';

export const getSearchResult = (searchInput: string): TeamDataValidation[] => {
  const fuseOptions = {
    // isCaseSensitive: false,
    // includeScore: false,
    shouldSort: false,
    includeMatches: true,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.0,
    // distance: 100,
    useExtendedSearch: true,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    // fieldNormWeight: 1,
    keys: [
      'name',
      'abbreviation',
      'description',
      'organization.name',
      'organization.abbreviation',
      'parentTeam.name',
      'parentTeam.abbreviation',
      'contactPoints.name',
      'scopeOfWork',
      'internalSubTeam.name',
      'internalSubTeam.abbreviation',
      'jobRoles.name',
      'jobRoles.abbreviation',
      'requiredSkills',
      'technologyKnowledge',
      'acquiredSkills',
      'customers'
    ]
  };

  const fuse = new Fuse(teamData, fuseOptions);

  const searchPattern = "'" + '"' + searchInput + '"';
  const results = fuse.search(searchPattern);
  const searchItems = results.map((result) => result.item).sort((a, b) => a.name.localeCompare(b.name));
  const sortedTeamData = teamData.sort((a, b) => a.name.localeCompare(b.name));
  console.log(searchItems);

  return searchInput ? searchItems : sortedTeamData;
};
