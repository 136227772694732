import { createContext, ReactNode, useState } from 'react';
import { OrganizationDataValidation } from '../types/OrganizationDataTypes';
import { TeamDataValidation } from '../types/TeamDataTypes';

interface SearchState {
  query: string;
  orgData: OrganizationDataValidation[];
  initialData: TeamDataValidation[];
  results: TeamDataValidation[];
  organizations: string[];
  jobRoles: string[];
  skills: string[];
  isOrganizationsView: boolean;
}

interface SearchContextType {
  searchState: SearchState;
  setSearchQuery: (query: string) => void;
  setSearchResults: (results: TeamDataValidation[]) => void;
  setInitialData: (initialData: TeamDataValidation[]) => void;
  setOrgData: (orgData: OrganizationDataValidation[]) => void;
  setOrganizations: (organizations: string[]) => void;
  setJobRoles: (jobs: string[]) => void;
  setSkills: (skills: string[]) => void;
  setIsOrganizationsView: (isOrganizationsView: boolean) => void;
}

export const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchState, setSearchState] = useState<SearchState>({
    query: '',
    orgData: [],
    initialData: [],
    results: [],
    organizations: [],
    jobRoles: [],
    skills: [],
    isOrganizationsView: true
  });

  const updateSearchState = <K extends keyof SearchState>(key: K, value: SearchState[K]) => {
    setSearchState((prevState) => ({ ...prevState, [key]: value }));
  };

  const setSearchQuery = (query: string) => updateSearchState('query', query);
  const setSearchResults = (results: TeamDataValidation[]) => updateSearchState('results', results);
  const setInitialData = (initialData: TeamDataValidation[]) => updateSearchState('initialData', initialData);
  const setOrgData = (orgData: OrganizationDataValidation[]) => updateSearchState('orgData', orgData);
  const setOrganizations = (organizations: string[]) => updateSearchState('organizations', organizations);
  const setJobRoles = (jobRoles: string[]) => updateSearchState('jobRoles', jobRoles);
  const setSkills = (skills: string[]) => updateSearchState('skills', skills);
  const setIsOrganizationsView = (isOrganizationsView: boolean) => updateSearchState('isOrganizationsView', isOrganizationsView);

  return (
    <SearchContext.Provider
      value={{
        searchState,
        setSearchQuery,
        setSearchResults,
        setInitialData,
        setOrgData,
        setOrganizations,
        setJobRoles,
        setSkills,
        setIsOrganizationsView
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
