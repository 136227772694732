import { Button } from '@exxonmobil/standard';
import { useContext } from 'react';
import { SearchContext } from '../contexts/searchContext';
import '../styles/card.css';
import { OrganizationCardValidation } from '../types/OrganizationCardTypes';

const OrganizationCard = ({ data, index }: OrganizationCardValidation) => {
  const contextData = useContext(SearchContext);

  return (
    <div
      className="custom-card"
      onClick={() => {
        contextData?.setOrganizations([data.organizationAbbreviation]);
        contextData?.setIsOrganizationsView(false);
      }}
    >
      <article
        className="em-c-card   em-c-card--img-fill   "
        aria-labelledby="cardTitle-:ri:"
        aria-describedby="cardDescription-:ri:"
      >
        <div className="em-c-card__body ">
          <div className="em-c-stacked-block">
            <div className="em-c-stacked-block__header">
              <div className="em-c-stacked-block__link">
                <img
                  src={`./assets/org-banner-${index + 1}.jpg`}
                  alt={`org-banner-${index + 1}`}
                  className="em-c-stacked-block__img"
                />
              </div>
              <div className="em-c-stacked-block__header" style={{ paddingLeft: '1.5rem', paddingTop: '1.5rem' }}>
                <div className="em-c-media-block__headline em-u-font-size-large em-u-margin-bottom" id="cardTitle-:ri:">
                  {data.organizationAbbreviation}
                </div>
              </div>
            </div>
            <div
              className="em-c-stacked-block__body em-u-font-size-small-2"
              style={{ paddingLeft: '1.5rem', marginTop: '-0.5rem' }}
            >
              <p className="em-c-stacked-block__desc" id="cardDescription-:ri:">
                {data.organizationName}
              </p>
            </div>
            <div
              className="em-c-stacked-block__footer em-u-margin-top"
              style={{ paddingLeft: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}
            >
              <Button
                // iconName="chevron-right-filled"
                // isIconButton
                label="Explore teams"
                onClick={() => {
                  contextData?.setOrganizations([data.organizationAbbreviation]);
                  contextData?.setIsOrganizationsView(false);
                }}
                size="em-c-btn--small"
                type="em-c-btn--secondary"
              />
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default OrganizationCard;
