import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@exxonmobil/standard';
import { useNavigate } from 'react-router-dom';
import { ModalContentTypes } from '../types/ModalContentTypes';
import TeamContent from './TeamContent';
import { useCompareContext } from '../hooks/useCompareContext';

const ModalContent = ({ subTeamData, team, org }: ModalContentTypes) => {
  const navigate = useNavigate();
  const { setFirstSelectedTeam } = useCompareContext();

  return (
    <Modal show={false} size="em-c-modal--large">
      <Breadcrumbs>
        <BreadcrumbsItem>EMIT</BreadcrumbsItem>
        <BreadcrumbsItem>{org}</BreadcrumbsItem>
        <BreadcrumbsItem>{team}</BreadcrumbsItem>
        <BreadcrumbsItem>{subTeamData.abbreviation || subTeamData.name}</BreadcrumbsItem>
      </Breadcrumbs>
      <ModalHeader
        icon="circle-question-mark-filled"
        kicker={subTeamData.name}
        onClick={() => {}}
        title={subTeamData.abbreviation || subTeamData.name}
      />
      <ModalBody>
        <TeamContent subTeamData={subTeamData} />
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            label="Compare"
            onClick={() => {
              navigate('/compare');
              setFirstSelectedTeam(subTeamData.name);
            }}
            type="em-c-btn--primary"
          />
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default ModalContent;
