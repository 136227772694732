import { useEffect } from 'react';
import { ContainerLayout, GridItem, GridLayout, Select } from '@exxonmobil/standard';
import data from '../../data/TeamData.json';
import TeamContent from '../TeamContent';
import { useCompareContext } from '../../hooks/useCompareContext';
import '../../styles/compare.css';

const Compare = () => {
  const { compareState, setFirstSelectedTeam, setFirstTeamData, setSecondSelectedTeam, setSecondTeamData } =
    useCompareContext();

  useEffect(() => {
    setFirstTeamData(data.find((item) => item.name === compareState.firstSelectedTeam)!);
    setSecondTeamData(data.find((item) => item.name === compareState.secondSelectedTeam)!);
  }, [compareState]);

  const handleFirstSelectChange = (teamName: string) => {
    setFirstSelectedTeam(teamName);
  };

  const handleSecondSelectChange = (teamName: string) => {
    setSecondSelectedTeam(teamName);
  };

  return (
    <ContainerLayout type="em-l-container">
      <GridLayout optionalClass="sticky-select" type="em-l-grid--2up">
        <GridItem>
          <Select id="select" name="select" onChange={handleFirstSelectChange} value={compareState.firstSelectedTeam}>
            <optgroup label="Team Options">
              <option value="default" disabled>
                {compareState.firstSelectedTeam || 'Please select team'}
              </option>
              {data.map((team) => (
                <option key={team.name} value={team.name}>
                  {team.name}
                </option>
              ))}
            </optgroup>
          </Select>
        </GridItem>
        <GridItem optionalClass="padding-left">
          <Select id="select" name="select" onChange={handleSecondSelectChange} value={compareState.secondSelectedTeam}>
            <optgroup label="Team Options">
              <option value="default" disabled>
                {compareState.secondSelectedTeam || 'Please select team'}
              </option>
              {data.map((team) => (
                <option key={team.name} value={team.name}>
                  {team.name}
                </option>
              ))}
            </optgroup>
          </Select>
        </GridItem>
      </GridLayout>
      <TeamContent
        subTeamData={compareState.firstTeamData || data[0]}
        subTeamCompareData={compareState.secondTeamData || data[0]}
      />
    </ContainerLayout>
  );
};

export default Compare;
