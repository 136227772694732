import { ContainerLayout } from '@exxonmobil/standard';

const CareerExample = () => {
  return (
    <ContainerLayout>
      <div id="img">
        <img alt="career_path_example" src={`./assets/career_example_1.png`} style={{ paddingTop: '1.5rem' }} />
      </div>
      <div id="img">
        <img alt="career_path_example" src={`./assets/career_example_2.png`} style={{ paddingTop: '1.5rem' }} />
      </div>
      <div id="img">
        <img alt="career_path_example" src={`./assets/career_example_3.png`} style={{ paddingTop: '1.5rem' }} />
      </div>
      <div id="img">
        <img alt="career_path_example" src={`./assets/career_example_4.png`} style={{ paddingTop: '1.5rem' }} />
      </div>
    </ContainerLayout>
  );
};

export default CareerExample;
