import { createContext, ReactNode, useState } from 'react';
import { TeamDataValidation } from '../types/TeamDataTypes';

interface CompareState {
    firstSelectedTeam: string;
    secondSelectedTeam: string;
    firstTeamData: TeamDataValidation;
    secondTeamData: TeamDataValidation;
}

interface CompareContextType {
    compareState: CompareState;
    setFirstSelectedTeam: (firstSelectedTeam: string) => void;
    setSecondSelectedTeam: (secondSelectedTeam: string) => void;
    setFirstTeamData: (firstTeamData: TeamDataValidation) => void;
    setSecondTeamData: (secondTeamData: TeamDataValidation) => void;
}

export const CompareContext = createContext<CompareContextType | undefined>(undefined);

export const CompareProvider = ({ children }: { children: ReactNode }) => {
    const [compareState, setCompareState] = useState<CompareState>({
        firstSelectedTeam: "",
        secondSelectedTeam: "",
        firstTeamData: {} as TeamDataValidation,
        secondTeamData: {} as TeamDataValidation
    });

    const updateCompareState = <K extends keyof CompareState>(key: K, value: CompareState[K]) => {
        setCompareState((prevState) => ({ ...prevState, [key]: value }));
      };

    const setFirstSelectedTeam = (firstSelectedTeam: string) => updateCompareState('firstSelectedTeam', firstSelectedTeam);
    const setSecondSelectedTeam = (secondSelectedTeam: string) => updateCompareState('secondSelectedTeam', secondSelectedTeam);
    const setFirstTeamData = (firstTeamData: TeamDataValidation) => updateCompareState('firstTeamData', firstTeamData);
    const setSecondTeamData = (secondTeamData: TeamDataValidation) => updateCompareState('secondTeamData', secondTeamData);

    return (
        <CompareContext.Provider
          value={{
            compareState,
            setFirstSelectedTeam,
            setSecondSelectedTeam,
            setFirstTeamData,
            setSecondTeamData
          }}
        >
          {children}
        </CompareContext.Provider>
      );
}



