import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { FunctionComponent, ReactNode } from 'react';
import { msalConfig } from './msalConfig';

interface IAzureADWithAuthCondition {
  children: ReactNode;
}

export const msalAuthProvider = new PublicClientApplication(msalConfig);

const AzureAuthProvider: FunctionComponent<IAzureADWithAuthCondition> = (props) => {
  return (
    <MsalProvider instance={msalAuthProvider}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {props.children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default AzureAuthProvider;
