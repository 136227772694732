import { ContainerLayout } from '@exxonmobil/standard';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const TOTAL_SLIDES = 9;
const Metrics: React.FC = () => {
  return (
    <ContainerLayout>
      <Slide duration={10000} transitionDuration={650}>
        {Array.from({ length: TOTAL_SLIDES }, (_, index) => (
          <img key={index} alt="metrics" style={{ width: '100%' }} src={`./assets/slide_${index + 1}.png`} />
        ))}
      </Slide>
    </ContainerLayout>
  );
};

export default Metrics;
