import { useContext } from 'react';
import { CompareContext } from '../contexts/compareContext';

export const useCompareContext = () => {
  const context = useContext(CompareContext);
  if (!context) {
    throw new Error('useCompareContext must be used within a CompareProvider');
  }
  return context;
};
