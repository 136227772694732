import { Button, ButtonGroup, Icon, Tag, TagGroup } from '@exxonmobil/standard';
import '../styles/teamcard.css';
import { TeamCardValidation } from '../types/TeamCardTypes';
import ModalContent from './ModalContent';

const TeamCard = ({ subTeamData, team, org, index }: TeamCardValidation) => {
  return (
    <>
      <div key={subTeamData.abbreviation} className="custom-card">
        <article
          className={'em-c-card team-card-' + String((index % 6) + 1)}
          aria-labelledby="cardTitle-:rc:"
          aria-describedby="cardDescription-:rc:"
        >
          <div className="em-c-card__body em-js-modal-trigger">
            <div
              className="em-c-media-block em-c-media-block--small"
              aria-labelledby="cardTitle-:rc:"
              aria-describedby="cardDescription-:rc:"
              role="figure"
            >
              <div className="em-c-media-block__body">
                <TagGroup>
                  <Tag label={org} />
                </TagGroup>
                <h4 className="em-u-margin-top" id="cardTitle-:rc:">
                  {subTeamData.abbreviation || subTeamData.name || '<team_name>'}
                </h4>
                <p className="em-c-media-block__desc" id="cardDescription-:r18:">
                  {team}
                </p>
              </div>
            </div>
          </div>
          <div className="em-c-card__footer learn-more-btn">
            <Icon name="chevron-right-filled" size="em-c-icon--medium" />
          </div>
          {/* <ButtonGroup optionalClass="em-u-margin-top-half">
              <Button
                iconName="chevron-right-filled"
                // isIconOnlyButton
                label="Learn more"
                // onClick={() => {}}
                size="em-c-btn--small"
                type="em-c-btn--primary"
                // optionalClass="em-js-modal-trigger"
              />
            </ButtonGroup> */}
        </article>
        {/* <SimpleCard
          bottomActions
          description={org + ' | ' + team}
          headline={subTeamData.abbreviation || subTeamData.name || '<team_name>'}
          optionalClass={'team-card-' + String((index % 6) + 1)}
        >
          <ButtonGroup optionalClass="em-u-margin-top-half">
            <Button
              iconName="chevron-right-filled"
              isIconOnlyButton
              label="Icon button"
              onClick={() => {}}
              size="em-c-btn--small"
              type="em-c-btn--primary"
              optionalClass="em-js-modal-trigger"
            />
          </ButtonGroup>
        </SimpleCard> */}
      </div>
      <ModalContent subTeamData={subTeamData} team={team} org={org} />
    </>
  );
};

export default TeamCard;
